<template>
  <div id="app" class="text-light bg-dark">
    <b-navbar toggleable="lg" type="dark" variant="danger">
      <b-button v-b-toggle.token-sidebar variant="danger">
        <b-icon icon="list"></b-icon>
      </b-button>
      <b-sidebar id="token-sidebar" title="Security Tokens" shadow>
        <div class="px-3 py-2">
          <b-link ref="/#/Alternative-2612">Alternative 2612 (ALT2612)</b-link>
        </div>
      </b-sidebar>
      <b-navbar-brand tag="h1" class="mb-0" href="/#/">Bitfinex Securities Analysis</b-navbar-brand>
    </b-navbar>
    <router-view/>
    <b-container>
      <br/><br/>
      Disclaimer: The information presented on this site is not authorized, sponsored, approved, or
      endorsed by Bitfinex or any of the issuers whose securities we attempt to value. The information
      is presented for informational purposes only. There may be data or calculation issues which we
      provide no warranty for.
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>
